<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          登録済月極・定期車両一覧
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-container v-if="drawer">
        <v-row v-if="isAdmin">
          <v-col cols="12" class="pt-0">
            <v-alert dense text type="info" :icon="false" class="mb-0">
              この機能は、月極・定期車両表示がオンになっている店舗でのみ利用可能です。
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" class="py-2">
            <v-autocomplete
              :outlined="$store.getters.isAdminUser"
              dense
              label="店舗名"
              :items="request_client_stores"
              item-text="name"
              item-value="id"
              v-model="selectedRequestClientStore"
              return-object
              hide-details
              :disabled="!$store.getters.isAdminUser || isLoading"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address">
          <v-col v-if="parkings.length > 3" cols="6">
            <v-row>
              <v-col cols="12" class="pt-0 pb-1">
                <small>▼絞り込み</small>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-select
                  outlined
                  dense
                  v-model="selectedParking"
                  return-object
                  :items="parkings"
                  item-text="PARKING_NAME"
                  item-value="PARKING_ID"
                  label="対象駐車場"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="12" class="pt-0 pb-1">
                <small>▼検索</small>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field v-model="searchCarNumber" append-icon="mdi-magnify" outlined dense clearable hide-details label="車両番号検索"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address">
          <v-col cols="12" class="py-1">
            <small>▼表示順番・表示件数</small>
          </v-col>
          <v-col cols="12" lg="6" class="py-0">
            <div class="d-flex">
              <v-select width="200" outlined dense v-model="sortBy" :items="sortItems" item-text="text" item-value="value" hide-details label="ソート項目指定" class="rounded-r-0"></v-select>
              <div style="width: 120px;"><v-select outlined dense v-model="sortDesc" :items="sortDescItems" item-text="text" item-value="value" hide-details background-color="grey lighten-2" class="rounded-l-0"></v-select></div>
            </div>
          </v-col>
          <v-col cols="12" lg="6" class="py-0">
            <div class="d-flex align-center justify-end">
              <div class="text-caption pl-4">表示件数</div>
              <div style="width: 100px;" class="pl-2"><v-select outlined dense v-model="itemsPerPage" :items="pageItems" item-text="text" item-value="value" hide-details></v-select></div>
            </div>
          </v-col>
        </v-row>
        <template v-if="selectedRequestClientStore">
          <v-card-text class="pa-3 pt-4 mt-4">
            <v-data-table
              :items="currentItems"
              item-key="id"
              :search="search"
              :page.sync="page"
              :headers="selectHeaders"
              :items-per-page="itemsPerPage"
              mobile-breakpoint="0"
              no-data-text=""
              @page-count="pageCount = $event"
              hide-default-footer
              fixed-header
              dense
            >
              <template v-slot:body="{ items, headers }">
                <tbody v-if="items.length > 0 && !isLoading">
                  <tr v-for="item in items" :key="item.WHITE_ID" @click.stop="showDialog(item)" class="table-row">
                    <td class="text-left">{{ carInfo(item) }}</td>
                    <td class="text-left hidden-md-and-down">{{ dayOfWeekFormatted(item) }}</td>
                    <td class="text-left">{{ startDteFormatted(item) }}</td>
                    <td class="text-left">{{ endDteFormatted(item) }}</td>
                    <td class="text-left hidden-md-and-down">{{ parkingName(item) }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="!isLoading">
                  <tr>
                    <td :colspan="headers.length" class="grey--text" style="text-align: center;">No results</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <v-container v-if="!isLoading && selectedRequestClientStore && !selectedRequestClientStore.address" class="text-center my-10">
              店舗address情報が設定されていないため情報を取得できません
            </v-container>
            <v-container v-else-if="!isLoading && tollMonthlyVehicles.length == 0" class="text-center my-10">
              該当する月極・定期車両がありません
            </v-container>
            <v-container v-if="isLoading" class="text-center my-10">
              <v-progress-circular
                :size="70"
                :width="7"
                color="grey"
                indeterminate
              ></v-progress-circular>
            </v-container>
            <v-divider class="mt-0 mb-1"></v-divider>
          </v-card-text>
          <v-row v-if="tollMonthlyVehicles.length != 0" justify="center">
            <v-col cols="10">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card>
    <v-dialog
      scrollable
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          月極・定期車両 登録詳細
        </v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text
          ref="modal_content"
          class="pt-7 pb-3"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="対象駐車場"
                :value="parkingName(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="車両情報"
                :value="carInfo(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="種別"
                :value="dayOfWeekFormatted(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="有効期間"
                :value="effectivePeriod(showedVehicle)"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                dense
                readonly
                label="備考"
                v-model="showedVehicle.NOTE"
                no-resize
                rows="3"
                class="mt-5"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="close">閉じる</v-btn>
          <v-btn small outlined color="error" @click.stop="confirmDialog = true">登録を削除する</v-btn>
          <v-btn small outlined color="warning" @click="edit">変更する</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          削除してよろしいですか？
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="closeConfirm">キャンセル</v-btn>
          <v-btn small outlined color="error" @click="deleteVehicle" :loading="deleteLoading">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment"
// import qs from 'qs'

export default {
  data: () => ({
    drawer: true,
    isLoading: false,
    updateLoading: false,
    deleteLoading: false,

    request_client_stores: [],
    selectedRequestClientStore: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    filter: 0,
    sortItems: [ { text: '対象駐車場', value: 'PARKING_ID' }, { text: '種別', value: 'DAYOFWEEK'}, { text: '契約開始日', value: 'START_DTE'}, { text: '契約終了日', value: 'END_DTE' }],
    sortDescItems: [ { text: '降順', value: true }, { text: '昇順', value: false }],
    sortBy: '',
    sortDesc: false,
    pageItems: [
      { text: '5', value: 5 },{ text: '10', value: 10 },{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'ALL', value: 1000 }
    ],
    parkings: [],
    selectedParking: {PARKING_ID: null, PARKING_NAME: ""},
    searchCarNumber: '',
    isSearchCarNumber: false,
    search: '',
    tollMonthlyVehicles: [],
    dialog: false,
    showedVehicle: '',
    confirmDialog: false
  }),
  computed: {
    parkingName () {
      return (value) => {
        return (value.PARKING_COUNT === 1 || value.PARKING_ID === 0) ? '全エリア・全フロア共通' : value.PARKING_NAME
      }
    },
    carInfo () {
      return (value) => {
        return `${value.PLACE} ${value.CLASS_NUMBER} ${value.KANA} ${value.CAR_NUMBER}`
      }
    },
    dayOfWeekFormatted () {
      return (elm) => {
        if (elm.DAYOFWEEK === 1) {
          return '休日のみ'
        } else if (elm.DAYOFWEEK === 2) {
          return '全日'
        } else {
          return '平日のみ'
        }
      }
    },
    selectHeaders: {
      get () { return (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md') ? this.mobileHeaders : this.headers}
    },
    headers () {
      return [
        { text: '車両番号', value: '', sortable: false, filterable: true },
        { text: '種別', value: 'DAYOFWEEK', sortable: false, filterable: false },
        { text: '契約開始日', value: 'START_DTE', sortable: false, filterable: false },
        { text: '契約終了日', value: 'END_DTE', sortable: false, filterable: false },
        { text: '対象駐車場', value: 'PARKING_ID', sortable: false, filterable: false }
      ]
    },
    mobileHeaders () {
      return [
        { text: '車両番号', value: '', sortable: false, filterable: true },
        { text: '契約開始日', value: 'START_DTE', sortable: false, filterable: false },
        { text: '契約終了日', value: 'END_DTE', sortable: false, filterable: false },
      ]
    },
    currentItems () {
      let selectItems = [...this.tollMonthlyVehicles]

    // 検索フィルター
    if (this.isSearchCarNumber) {
      selectItems = selectItems.filter((vehicle) => {
        const number = vehicle.PLACE + vehicle.CLASS_NUMBER + vehicle.KANA + vehicle.CAR_NUMBER;
        return number && number.indexOf(this.searchCarNumber) !== -1;
      });
    }

    // 駐車場フィルター
    if (this.selectedParking && this.selectedParking.PARKING_ID === 0) {
      selectItems = selectItems.filter((vehicle) => Number(vehicle.PARKING_ID) === 0);
    } else if (this.selectedParking && this.selectedParking.PARKING_ID !== null) {
      selectItems = selectItems.filter((vehicle) => Number(vehicle.PARKING_ID) === Number(this.selectedParking.PARKING_ID));
    }

    // ソート処理
    if (this.sortBy) {
      selectItems.sort((a, b) => {
        let compareA, compareB;
        
        switch (this.sortBy) {
          case 'PARKING_ID':
            compareA = a.PARKING_ID;
            compareB = b.PARKING_ID;
            break;
          case 'DAYOFWEEK':
            compareA = a.DAYOFWEEK;
            compareB = b.DAYOFWEEK;
            break;
          case 'START_DTE':
            compareA = new Date(a.START_DTE);
            compareB = new Date(b.START_DTE);
            break;
          case 'END_DTE':
            compareA = new Date(a.END_DTE);
            compareB = new Date(b.END_DTE);
            break;
          default:
            return 0;
        }

        if (this.sortDesc) {
          return compareA > compareB ? -1 : 1;
        } else {
          return compareA < compareB ? -1 : 1;
        }
      });
    }
    return selectItems
    },
    startDteFormatted () {
      return (elm) => {
        return moment(elm.START_DTE).format('yyyy/MM/DD')
      }
    },
    endDteFormatted () {
      return (elm) => {
        const endDteStr = moment(elm.END_DTE).format('yyyy/MM/DD')
        return endDteStr === '9999/12/31' ? '---' : moment(elm.END_DTE).subtract(1, 'days').format('yyyy/MM/DD')
      }
    },
    effectivePeriod () {
      return (elm) => {
        const endDteStr = moment(elm.END_DTE).format('yyyy-MM-DD')
        if (endDteStr === '9999-12-31') {
          return `${moment(elm.START_DTE).format('YY/MM/DD')} 〜`
        } else {
          return `${moment(elm.START_DTE).format('YY/MM/DD')} 〜 ${moment(elm.END_DTE).format('yyyy-MM-DD')}`
        }
      }
    },
    isAdmin () {
      return this.$store.getters.isAdminUser
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getParkings()
        this.selectedParking = {PARKING_ID: null, PARKING_NAME: ""}
        this.getTollMonthlyVehicles()
      }
    },
    searchCarNumber (value) {
      if (value === '' || value === null) {
        this.isSearchCarNumber = false
      } else {
        this.isSearchCarNumber = true
      }
    }
  },
  methods: {
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url, { params: { userId: 1} })
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
              this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          console.log(err)
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    async getParkings () {
      const url = `${process.env.VUE_APP_BASE_URL}/parkings/parking_names?&ip_address=${this.selectedRequestClientStore.address}`
      this.axios
        .get(url)
        .then(response => {
          // 特定の店舗のみの処置 2024-12-14 やめたい
          if (response.data.length > 1) {
            this.parkings = [{PARKING_ID: null, PARKING_NAME: ""}, {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}].concat(response.data)
          } else {
            this.parkings = [{PARKING_ID: null, PARKING_NAME: ""}, {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}]
          }
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
            this.parkings = [{PARKING_ID: null, PARKING_NAME: ""}, {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}]
          }
        })
    },
    async getTollMonthlyVehicles () {
      this.filter = 0
      this.sortBy = ''
      this.itemsPerPage = 10
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}toll_monthly_vehicles?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      await this.axios
        .get(url)
        .then(response => {
          console.log(response.data)
          this.tollMonthlyVehicles = response.data
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    async deleteVehicle () {
      this.deleteLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}toll_monthly_vehicles/${this.showedVehicle.WHITE_ID}`
      let data = {
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
      }
      const options = {
        url: url,
        method: 'DELETE',
        data: data,
      }
      await this.axios(options)
      .then(() => {
        this.$buefy.toast.open({
          message: `登録を削除しました`,
          type: "is-info"
        })
        this.deleteLoading = false
        this.getTollMonthlyVehicles()
        this.closeConfirm()
        this.close()
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 404) {
          this.$buefy.toast.open({
            message: `対象の車両登録が見つかりません`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `登録削除に失敗しました`,
            type: "is-danger",
          })
        }
        this.closeConfirm()
        this.deleteLoading = false
      })
    },
    showDialog (vehicle) {
      this.showedVehicle = vehicle
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    closeConfirm () {
      this.confirmDialog = false
    },
    edit () {
      this.$router.push({name: "editTollMonthlyVehicle", params: { id: this.showedVehicle.WHITE_ID, selectedRequestClientStore: this.selectedRequestClientStore, tollMonthlyVehicle: this.showedVehicle }, props: true })
    }
  },
  mounted() {
    this.getRequestClientStores()
  }
}
</script>

<style scoped>

</style>