<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          月極・定期車両 登録内容変更
        </v-toolbar-title>
      </v-toolbar>
      <!-- 登録フォーム -->
      <ValidationObserver v-slot="{ invalid }">
        <v-container>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-0">
                <small>
                  登録内容の編集画面です。変更内容を入力してください。
                </small>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="店舗名"
                rules="required"
              >
                <v-autocomplete
                  :outlined="$store.getters.isAdminUser"
                  dense
                  label="店舗名"
                  :items="request_client_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedRequestClientStore"
                  return-object
                  disabled
                  :error-messages="errors"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col v-if="selectedRequestClientStore" cols="12" lg="6" class="py-0">
              <v-text-field
                outlined
                dense
                label="対象駐車場"
                v-model="selectedParking.PARKING_NAME"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <template v-if="selectedRequestClientStore">
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  月極・定期車両情報を入力してください<span class="red--text">(必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="地域"
                  rules="required|car_place"
                >
                  <v-text-field
                    outlined
                    dense
                    label="地域 (例: 旭川)"
                    v-model="carPlace"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="クラス"
                  rules="required|car_class"
                >
                  <v-text-field
                    outlined
                    dense
                    label="クラス (例: 500)"
                    v-model="carClass"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="かな"
                  rules="required|car_kana"
                >
                  <v-text-field
                    outlined
                    dense
                    label="かな (例: あ)"
                    v-model="carKana"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="4桁ナンバー"
                  rules="required|car_number"
                >
                  <v-text-field
                    outlined
                    dense
                    label="4桁ナンバー (例: 3, 88, 456)"
                    v-model="carNumber"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  種別を選択してください<span class="red--text">(必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-select
                  outlined
                  dense
                  v-model="dayOfWeek"
                  :items="dayOfWeekList"
                  item-text="name"
                  item-value="value"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  契約の開始日・終了日を選択してください<span class="red--text">(開始日は必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-menu
                  v-model="showTargetStartDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="開始日"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="selectedTargetStartDateFormatted"
                        label="開始日"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetStartDate"
                    :max="maxStartDate ? maxStartDate : ''"
                    no-title
                    locale="ja"
                    @input="showTargetStartDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-menu
                  v-model="showTargetEndDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      v-model="selectedTargetEndDateFormatted"
                      label="終了日"
                      prepend-icon="mdi-arrow-right"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetEndDate"
                    :min="minEndDate ? minEndDate : ''"
                    no-title
                    locale="ja"
                    @input="showTargetEndDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="備考"
                  :rules="`max:${noteLimit}`"
                >
                  <v-textarea
                    outlined
                    dense
                    v-model="note"
                    no-resize
                    rows="4"
                    label="備考"
                    :counter="noteLimit"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
          </template>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="brue-gray" class="mr-2" to="/">戻る</v-btn>
          <v-btn small outlined color="primary" :disabled="invalid" @click.stop="dialog = true">更新内容確認</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </ValidationObserver>
      <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
      >
        <v-card>
          <v-card-title>
            更新内容確認
          </v-card-title>
          <v-divider class="my-0"></v-divider>
          <v-card-text>
            <v-row>
              <small class="my-5">以下の内容で更新します。よろしいですか？</small>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="店舗名"
                  :value="selectedRequestClientStore ? selectedRequestClientStore.name : ''"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col v-if="parkings.length > 2" cols="12" class="py-0">
                <v-text-field
                  label="対象駐車場"
                  v-model="selectedParking.PARKING_NAME"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="ナンバープレート情報"
                  v-model="numberInfo"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="種別"
                  v-model="selectedDayOfWeekName"
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="selectedTargetStartDateFormatted || selectedTargetEndDateFormatted">
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="selectedTargetStartDateFormatted"
                    label="契約開始日"
                    prepend-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pl-0 py-0">
                  <v-text-field
                    v-model="selectedTargetEndDateFormatted"
                    label="契約終了日"
                    prepend-icon="mdi-arrow-right"
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="note"
                  no-resize
                  rows="4"
                  label="備考"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined color="blue-grey" @click="dialog = false">修正する</v-btn>
            <v-btn small outlined color="primary" @click="save" :loading="isLoading">更新する</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment"
import qs from 'qs'

export default {
  name: 'EditTollMonthlyVehicle',
  data: () => ({
    showTargetStartDateSelector: false,
    showTargetEndDateSelector: false,
    dialog: false,

    request_client_stores: [],
    selectedRequestClientStore: null,

    parkings: [],
    selectedParking: {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"},
    ownerInfo: '',
    carPlace: '',
    carClass: '',
    carKana: '',
    carNumber: '',
    dayOfWeek: 0,
    dayOfWeekList: [
      { name: '平日', value: 0 },
      { name: '休日', value: 1 },
      { name: '全日', value: 2 }
    ],
    selectedTargetStartDate: '',
    selectedTargetStartDateFormatted: '',
    selectedTargetEndDate: '',
    selectedTargetEndDateFormatted: '',
    note: '',
    originalRequestId: null,

    isLoading: false,
  }),
  computed: {
    minEndDate () {
      return this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).add(1, 'days').format('yyyy-MM-DD') : null
    },
    maxStartDate () {
      return this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).subtract(1, 'days').format('yyyy-MM-DD') : null
    },
    noteLimit () {
      return 99
    },
    numberInfo () {
      return `${this.carPlace} ${this.carClass} ${this.carKana} ${this.carNumber}`
    },
    selectedDayOfWeekName() {
      const found = this.dayOfWeekList.find(day => day.value === this.dayOfWeek)
      return found ? found.name : ''
    }
  },
  watch: {
    selectedTargetStartDate (value) {
      if (value) { this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDateFormatted (value) {
      if (!value) { this.selectedTargetStartDate = '' }
    },
    selectedTargetEndDateFormatted (value) {
      if (!value) { this.selectedTargetEndDate = '' }
    },
  },
  methods: {
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          this.setOriginalRequest()
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    setOriginalRequest () {
      this.isEdit = true
      this.selectedRequestClientStore = this.$route.params.selectedRequestClientStore
      const selectedVehicle = this.$route.params.tollMonthlyVehicle
      const endDteStr = moment(selectedVehicle.END_DTE).format('yyyy-MM-DD')
      this.carNumber = selectedVehicle.CAR_NUMBER.replace(/[^0-9]/g, '')
      this.carPlace = selectedVehicle.PLACE
      this.carClass = selectedVehicle.CLASS_NUMBER
      this.carKana = selectedVehicle.KANA
      this.note = selectedVehicle.NOTE
      this.dayOfWeek = selectedVehicle.DAYOFWEEK
      this.selectedTargetStartDate = moment(selectedVehicle.START_DTE).format('yyyy-MM-DD')
      this.selectedTargetEndDate = endDteStr === '9999-12-31' ? null : moment(selectedVehicle.END_DTE).subtract(1, 'days').format('yyyy-MM-DD')
      this.originalRequestId = selectedVehicle.WHITE_ID
      if (selectedVehicle.PARKING_ID == 0) {
        this.selectedParking = {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}
      } else {
        this.selectedParking = { PARKING_ID: selectedVehicle.PARKING_ID, PARKING_NAME: selectedVehicle.PARKING_NAME }
      }
    },
    async save () {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}toll_monthly_vehicles/${this.$route.params.id}`
      let data = {
        toll_monthly_vehicle: {
          DAY_OF_WEEK: this.dayOfWeek,
          PLACE: this.carPlace,
          CLASS_NUMBER: this.carClass,
          KANA: this.carKana,
          CAR_NUMBER: ('****' + this.carNumber).slice(-4),
          START_DTE: this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).format('yyyy/MM/DD') : '',
          END_DTE: this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).add(1, 'days').format('yyyy/MM/DD') : '9999-12-31',
          PARKING_ID: this.selectedParking.PARKING_ID,
          NOTE: this.note
        },
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
      }
      const options = {
        url: url,
        method: 'PUT',
        data: qs.stringify(data),
      }
      await this.axios(options)
      .then(() => {
        this.$router.push({path: "/toll-monthly-vehicles"})
        this.$buefy.toast.open({
          message: `月極・定期車両を更新しました`,
          type: "is-info"
        })
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 409) {
          this.$buefy.toast.open({
            message: `この車両はすでに登録されています`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `月極・定期車両の更新に失敗しました`,
            type: "is-danger",
          })
        }
        this.isLoading = false
        this.dialog = false
      })
    },
    applyRecord (record) {
      this.carPlace = record.PLACE
      this.carClass = record.CLASS_NUMBER
      this.carKana = record.KANA
      this.carNumber = record.CAR_NUMBER.replace(/\*/g,'')
      this.inOutRecordsDialog = false
    },
    closeDialog () {
      this.inOutRecordsDialog = false
    },
  },
  mounted() {
    this.getRequestClientStores()
  },
}
</script>

<style scoped>
</style>
