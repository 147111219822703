<template>
  <v-list nav dense>
    <v-list-item-group v-model="group" v-if="$store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariUser || $store.getters.isAeonMakuhariAccessible || $store.getters.isAeonYonoAdmin || $store.getters.isMarinpia || $store.getters.isRakeTownAdmin">
      <v-list-item link to="/">
        <v-list-item-title class="text-caption">ホーム</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="$store.getters.isAdminUser || $store.getters.isAeonYonoAdmin || $store.getters.isRakeTownAdmin" link to="/dashboard">
        <v-list-item-title class="text-caption">ダッシュボード<span v-if="$store.getters.isAdminUser">(イオンモール与野)</span></v-list-item-title>
      </v-list-item>
      <v-list-item v-if="$store.getters.isAdminUser || $store.getters.isSendaiAdmin" link to="/dashboard_with_reservations">
        <v-list-item-title class="text-caption">ダッシュボード<span v-if="$store.getters.isAdminUser">(仙台空港)</span></v-list-item-title>
      </v-list-item>
      <v-list-group :value="true" v-if="$store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin || $store.getters.isAeonYonoAdmin || $store.getters.isMarinpia || $store.getters.isRakeTownAdmin">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">駐車料金請求取消依頼</v-list-item-title>
        </template>
        <v-list-item link to="/new-delete-request">
          <v-list-item-title class="text-caption ml-5">申請</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/delete-request-histories">
          <v-list-item-title class="text-caption ml-5">申請状況一覧</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="true" v-if="($store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin || $store.getters.isAeonYonoAdmin || $store.getters.isRakeTownAdmin) && !$store.getters.isMarinpia">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">精算不要車両</v-list-item-title>
        </template>
        <v-list-item v-if="$store.getters.isSetStoreAddress" link to="/new-no-payment-required-vehicle">
          <v-list-item-title class="text-caption ml-5">登録</v-list-item-title>
        </v-list-item>
        <v-list-item v-else disabled link to="/new-no-payment-required-vehicle">
          <v-list-item-title class="text-caption ml-5">登録（準備中）</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$store.getters.isSetStoreAddress" link to="/no-payment-required-vehicles">
          <v-list-item-title class="text-caption ml-5">登録済車両一覧</v-list-item-title>
        </v-list-item>
        <v-list-item v-else disabled link to="/no-payment-required-vehicles">
          <v-list-item-title class="text-caption ml-5">登録済車両一覧（準備中）</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$store.getters.isSetStoreAddress" link to="/output-no-payment-required-vehicle">
          <v-list-item-title class="text-caption ml-5">CSV出力</v-list-item-title>
        </v-list-item>
        <v-list-item v-else disabled link to="/output-no-payment-required-vehicles">
          <v-list-item-title class="text-caption ml-5">CSV出力（準備中）</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="true" v-if="($store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin || $store.getters.isAeonYonoAdmin || $store.getters.isRakeTownAdmin) && !$store.getters.isMarinpia">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">アラート通知車両登録依頼</v-list-item-title>
        </template>
          <v-list-item link to="/new-alert-vehicles">
          <v-list-item-title class="text-caption ml-5">登録</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$store.getters.isSetStoreAddress" link to="/alert-vehicles">
          <v-list-item-title class="text-caption ml-5">登録済車両一覧</v-list-item-title>
        </v-list-item>
        <v-list-item v-else link disabled to="/alert-vehicles">
          <v-list-item-title class="text-caption ml-5">登録済車両一覧(準備中)</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="true" v-if="$store.getters.isSetStoreAddress && $store.getters.isGateControlStore && !$store.getters.isMarinpia">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">入場許可車両登録依頼</v-list-item-title>
        </template>
        <v-list-item link to="/new-gate-control-vehicles">
          <v-list-item-title class="text-caption ml-5">登録</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/gate-control-vehicles">
          <v-list-item-title class="text-caption ml-5">登録済車両一覧</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- エリア表示 -->
      <v-list-item-group v-model="group" v-if="($store.getters.isAdminUser || $store.getters.isAeonMakuhariUser || $store.getters.isAeonYonoAdmin || $store.getters.isRakeTownAdmin) && !$store.getters.isMarinpia">
        <v-list-item link to="/parking_maps" v-if="$store.getters.isAdminUser || $store.getters.isAeonMakuhariUser">
          <v-list-item-title class="text-caption">フロア・エリア検索</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/vehicle-informations" v-if="$store.getters.isAdminUser || $store.getters.isAeonMakuhariUser || $store.getters.isAeonYonoAdmin || $store.getters.isRakeTownAdmin">
          <v-list-item-title class="text-caption">入出場履歴<span v-if="$store.getters.isAdminUser">(幕張新都心,イオンモール与野,レイクタウン)</span></v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-group :value="true" v-if="$store.getters.isSetStoreAddress && $store.getters.isAlertNotificationFlagged && !$store.getters.isMarinpia">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">大型バスアラート機能</v-list-item-title>
        </template>
        <v-list-item link to="/new-alert-notifications">
          <v-list-item-title class="text-caption ml-5">通知先登録</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/alert-notifications">
          <v-list-item-title class="text-caption ml-5">大型バスアラート通知先一覧</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list-item-group>
    <!-- 仙台空港専用 -->
    <v-list-item-group v-model="group">
      <v-list-group :value="true" v-if="$store.getters.isSetStoreAddress && ($store.getters.isAdminUser || $store.getters.canManageUnpaidAlert)">
        <template v-slot:activator>
          <v-list-item-title>駐車料金未払い車両アラート機能</v-list-item-title>
        </template>
        <v-list-item link to="/unpaid-alert-notifications">
          <v-list-item-title class="text-caption ml-5">一覧</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="true" v-if="$store.getters.isSetStoreAddress && ($store.getters.isAdminUser || $store.getters.canManageLongTerm)">
        <template v-slot:activator>
          <v-list-item-title>長期駐車車両アラート機能</v-list-item-title>
        </template>
        <v-list-item link to="/long-term-parking-alert-notifications">
          <v-list-item-title class="text-caption ml-5">一覧</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="true" v-if="$store.getters.isSetStoreAddress && ($store.getters.isAdminUser || $store.getters.canManageAccessible)">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">障害者一般車両</v-list-item-title>
        </template>
          <v-list-item link to="/new-accessible-vehicles">
            <v-list-item-title class="text-caption ml-5">登録</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/accessible-vehicles">
            <v-list-item-title class="text-caption ml-5">登録済車両一覧</v-list-item-title>
          </v-list-item>
      </v-list-group>
      <v-list-group :value="true" v-if="$store.getters.isSetStoreAddress && ($store.getters.isAdminUser || $store.getters.canManageReservation)">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">予約情報</v-list-item-title>
        </template>
        <v-list-item link to="/reservations">
          <v-list-item-title class="text-caption ml-5">予約情報一覧</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="true" v-if="$store.getters.isSetStoreAddress && ($store.getters.isAdminUser || $store.getters.canManageVehicle)">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">履歴照会</v-list-item-title>
        </template>
        <v-list-item link to="/sendai-airport-vehicle-informations">
          <v-list-item-title class="text-caption ml-5">入出庫照会</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/sales-inquiries">
          <v-list-item-title class="text-caption ml-5">売上照会</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="true" v-if="$store.getters.isSetStoreAddress && ($store.getters.isAdminUser || $store.getters.isTollMonthly)">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">月極・定期車両</v-list-item-title>
        </template>
        <v-list-item link to="/new-toll-monthly-vehicles">
          <v-list-item-title class="text-caption ml-5">登録</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/toll-monthly-vehicles">
          <v-list-item-title class="text-caption ml-5">登録済車両一覧</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list-item-group>

    <v-divider></v-divider>
    <v-list-item-group v-if="$store.getters.isAdminUser">
      <v-list-item link to="/account">
        <v-list-item-title class="text-caption">アカウント設定</v-list-item-title>
      </v-list-item>
      <v-list-item link target="blank" href="https://smapa-request-app.s3.ap-northeast-1.amazonaws.com/%E3%81%93%E3%82%99%E5%88%A9%E7%94%A8%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6.pdf">
        <v-icon small>mdi-help-circle-outline</v-icon>
        <v-list-item-title class="text-caption">ご利用案内</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-title class="text-caption">ログアウト</v-list-item-title>
      </v-list-item>
      <v-divider v-if="$store.getters.isAdminUser"></v-divider>
      <v-list-group :value="true" v-if="$store.getters.isAdminUser">
        <template v-slot:activator>
          <v-list-item-title class="text-caption">管理機能</v-list-item-title>
        </template>
        <v-list-item link to="/request_client_users">
          <v-list-item-title class="text-caption ml-5">ユーザー管理</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/request_client_stores">
          <v-list-item-title class="text-caption ml-5">店舗管理</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/delete-request-reasons">
          <v-list-item-title class="text-caption ml-5">請求取消依頼理由管理</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list-item-group>
    <v-list-item-group v-else>
      <v-list-item link to="/account">
        <v-list-item-title class="text-caption">アカウント設定</v-list-item-title>
      </v-list-item>
      <v-list-item link target="blank" href="https://smapa-request-app.s3.ap-northeast-1.amazonaws.com/%E3%81%93%E3%82%99%E5%88%A9%E7%94%A8%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6.pdf">
        <v-icon small>mdi-help-circle-outline</v-icon>
        <v-list-item-title class="text-caption">ご利用案内</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-title class="text-caption">ログアウト</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>

export default {
  data: () => ({
    group: null,
  }),
  methods: {
    logout() {
      this.$store
      .dispatch("signOut")
      .then(() => {
        this.$router.push({path: "/login"})
      })
    }
  },
}
</script>

<style scoped>
</style>