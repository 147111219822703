<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          登録済精算不要車両一覧
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="selectedRequestClientStore">
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-alert dense text type="info" :icon="false" class="mb-0">
              <span v-if="selectedRequestClientStore.white_limit && noPaymentRequiredVehiclesCount">
                現在の精算不要車両登録数は {{ noPaymentRequiredVehiclesCount }}件 です。登録できる精算不要車両の上限は {{ selectedRequestClientStore.white_limit }}件 です。
                <br/></span>
              ※ 有効期限が迫っている車両（残り1か月未満）は背景が赤く表示されますのでご確認ください。
            </v-alert>
          </v-col>
          <v-col v-if="selectedRequestClientStore.white_limit && !isRegisterableNewWhite" cols="12" class="pt-0 mt-0 mb-4">
            <v-alert dense text type="error" class="mb-0">
              <small>
                精算不要車両登録の上限に達しています。新規登録する場合は既存の登録から不要なものを削除してください。
              </small>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-container v-if="drawer">
        <v-row>
          <v-col cols="12" lg="4" class="pt-0 pb-md-0 pb-4">
            <v-autocomplete
              :outlined="$store.getters.isAdminUser"
              dense
              label="店舗名"
              :items="request_client_stores"
              item-text="name"
              item-value="id"
              v-model="selectedRequestClientStore"
              return-object
              hide-details
              :disabled="!$store.getters.isAdminUser || isLoading"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address">
          <v-col cols="12" class="py-1">
            <small>▼絞り込み</small>
          </v-col>
          <v-col v-if="parkings.length > 3" cols="12" lg="6" class="py-0">
            <v-select
              outlined
              dense
              v-model="selectedParking"
              return-object
              :items="parkings"
              item-text="PARKING_NAME"
              item-value="PARKING_ID"
              label="対象駐車場"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" class="py-0">
            <v-select outlined dense v-model="filter" :items="filterItems" item-text="text" item-value="value" hide-details label="有効期限"></v-select>
          </v-col>
        </v-row>
        <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address">
          <v-col cols="12" class="py-1">
            <small>▼検索</small>
          </v-col>
          <v-col cols="12" lg="6" class="py-0">
            <v-text-field v-model="searchOrganization" append-icon="mdi-magnify" outlined dense clearable hide-details label="所属検索"></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="py-0">
            <v-text-field v-model="searchCarNumber" append-icon="mdi-magnify" outlined dense clearable hide-details label="車両番号検索"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address">
          <v-col cols="12" class="py-1">
            <small>▼表示順番・表示件数</small>
          </v-col>
          <v-col cols="12" lg="6" class="py-0">
            <div class="d-flex">
              <v-select width="200" outlined dense v-model="sortBy" :items="sortItems" item-text="text" item-value="value" hide-details label="ソート項目指定" class="rounded-r-0"></v-select>
              <div style="width: 120px;"><v-select outlined dense v-model="sortDesc" :items="sortDescSelect" item-text="text" item-value="value" hide-details background-color="grey lighten-2" class="rounded-l-0"></v-select></div>
            </div>
          </v-col>
          <v-col cols="12" lg="6" class="py-0">
            <div class="d-flex align-center justify-end">
              <div class="text-caption pl-4">表示件数</div>
              <div style="width: 100px;" class="pl-2"><v-select outlined dense v-model="itemsPerPage" :items="pageItems" item-text="text" item-value="value" hide-details></v-select></div>
            </div>
          </v-col>
        </v-row>
        <!-- parking_info -->
        <v-row>
          <v-col md="6" cols="12" class="pt-1 pb-md-0 pb-2">
            <template  v-if="selectedRequestClientStore && selectedRequestClientStore.address && showParkingInfo && showOnlyStore">
              <small>■ 駐車状況</small>
              <v-simple-table dense class="custom-table">
                <template>
                  <thead>
                    <tr>
                      <th class="text-center header-cell"></th>
                      <th class="text-center header-cell">全体</th>
                      <th class="text-center header-cell">契約車両</th>
                      <th class="text-center header-cell">一般車両</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in parkingInfo" :key="item.info">
                      <td class="info-cell">{{ item.info }}</td>
                      <td>{{ item.total }}</td>
                      <td>{{ item.no_payment }}</td>
                      <td>{{ item.general }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="selectedRequestClientStore">
        <v-card-text class="pa-3 pt-4">
          <v-data-table
            :items="currentItems"
            item-key="id"
            :search="search"
            :page.sync="page"
            :headers="selectHeaders"
            :items-per-page="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            mobile-breakpoint="0"
            no-data-text=""
            @page-count="pageCount = $event"
            hide-default-footer
            fixed-header
            dense
          >
            <template v-slot:body="{ items, headers }">
              <tbody v-if="items.length > 0 && !isLoading">
                <tr v-for="item in items" :key="item.WHITE_ID" @click.stop="showDialog(item)" class="table-row">
                  <td class="text-left">{{ organization(item) }}</td>
                  <td class="text-left">{{ carInfo(item) }}</td>
                  <td class="text-left" :class="nearDeadlineStyle(item)">{{ effectivePeriod(item) }}</td>
                  <td class="text-left hidden-md-and-down" :class="parkedStyle(item)">{{ inOutStatus(item) }}</td>
                  <td class="text-left hidden-md-and-down">{{ entrantTimeFormatted(item) }}</td>
                  <td class="text-left hidden-md-and-down">{{ parkingName(item) }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="!isLoading">
                <tr>
                  <td :colspan="headers.length" class="grey--text" style="text-align: center;">No results</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-container v-if="!isLoading && selectedRequestClientStore && !selectedRequestClientStore.address" class="text-center my-10">
            店舗address情報が設定されていないため情報を取得できません
          </v-container>
          <v-container v-else-if="!isLoading && noPaymentRequiredVehicles.length == 0" class="text-center my-10">
            該当する精算不要車両がありません
          </v-container>
          <v-container v-if="isLoading" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-divider class="mt-0 mb-1"></v-divider>
        </v-card-text>
        <v-row v-if="noPaymentRequiredVehicles.length != 0" justify="center">
          <v-col cols="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>
    <v-dialog
      scrollable
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          精算不要車両 登録詳細
        </v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text
          ref="modal_content"
          class="pt-7 pb-3"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="対象駐車場"
                :value="parkingName(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="登録日時"
                :value="registedDateFormatted(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="所属"
                :value="organization(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="ご利用者名"
                :value="ownerName(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="車両情報"
                :value="carInfo(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="有効期間"
                :value="effectivePeriod(showedVehicle)"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                dense
                readonly
                label="備考"
                v-model="showedVehicle.NOTE"
                no-resize
                rows="3"
                class="mt-5"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="close">閉じる</v-btn>
          <v-btn v-if="showedVehicle.KIND_ID == 1" small outlined color="error" @click.stop="confirmDialog = true">登録を削除する</v-btn>
          <v-btn small outlined color="warning" @click="edit">変更する</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          削除してよろしいですか？
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="closeConfirm">キャンセル</v-btn>
          <v-btn small outlined color="error" @click="deleteVehicle" :loading="deleteLoading">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment"

export default {
  data: () => ({
    isLoading: false,
    updateLoading: false,
    deleteLoading: false,
    drawer: true,

    request_client_stores: [],
    selectedRequestClientStore: null,
    noPaymentRequiredVehiclesCount: null,
    isRegisterableNewWhite: true,
    filterNumber: '',

    parkings: [],
    selectedParking: {PARKING_ID: null, PARKING_NAME: ""},
    // レイクタウン対応 身障者用のローカルサーバーが準備されているが載せないでほしいとの要望
    rakeTownParkings: [
      {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"},
      {PARKING_ID: 1, PARKING_NAME: "レイク"},
      {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"},
      {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"},
      {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}
    ],
    noPaymentRequiredVehicles: [],
    dialog: false,
    showedVehicle: '',
    organizationFilterValue: '',
    carNumberFilterValue: '',
    searchCarNumber: null,
    searchOrganization: null,
    isSearchOrganization: false,
    isSearchCarNumber: false,
    search: '',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    sortItems: [{ text: '所属', value: 'ORGANIZATION'}, { text: '登録日時', value: 'REGIST_DTE'}, { text: '有効期限(開始)', value: 'START_DTE' }, { text: '有効期限(終了)', value: 'END_DTE' }, { text: '入庫状況', value: 'VEHICLE_STATUS_ID'}],
    sortDescItems: [ { text: '降順', value: true }, { text: '昇順', value: false }],
    sortDescInOutStatus: [ { text: '入庫中', value: false }, { text: '', value: true }],
    sortBy: '',
    sortDesc: false,
    filterItems: [ { text: '', value: 0 }, { text: '有効期限(1ヶ月前)', value: 1 }, { text: '有効期限(2ヶ月前)', value: 2 }],
    filter: 0,
    confirmDialog: false,
    parkingInfo: [],
    showParkingInfo: false
  }),
  computed: {
    parkingName () {
      return (value) => {
        return (value.PARKING_COUNT === 1 || value.PARKING_ID === 0) ? '全エリア・全フロア共通' : value.PARKING_NAME
      }
    },
    organization () {
      return (value) => {
        return (value.ORGANIZATION === '' || value.ORGANIZATION === null) ? '' : value.ORGANIZATION
      }
    },
    ownerName () {
      return (value) => {
        return (value.OWNER_NAME === '' || value.OWNER_NAME === null) ? '' : value.OWNER_NAME
      }
    },
    inOutStatus () {
      return (value) => {
        return value.VEHICLE_STATUS_ID === 0 ? '入庫中' : ''
      }
    },
    registedDateFormatted () {
      return (value) => {
        return moment(value.REGIST_DTE).format('yyyy/MM/DD HH:mm:ss')
      }
    },
    entrantTimeFormatted () {
      return (value) => {
        return value.ENTRANT_TIME !== null ? moment(value.ENTRANT_TIME).format('yyyy/MM/DD HH:mm:ss') : ''
      }
    },
    carInfo () {
      return (value) => {
        return `${value.PLACE} ${value.CLASS_NUMBER} ${value.KANA} ${value.CAR_NUMBER}`
      }
    },
    effectivePeriod () {
      return (value) => {
        if (value.START_DTE && value.END_DTE) {
          return `${moment(value.START_DTE).format('YY/MM/DD')} 〜 ${moment(value.END_DTE).format('YY/MM/DD')}`
        } else if (value.START_DTE) {
          return `${moment(value.START_DTE).format('YY/MM/DD')} 〜`
        } else if (value.END_DTE) {
          return `〜 ${moment(value.END_DTE).format('YY/MM/DD')}`
        } else {
          return '設定なし'
        }
      }
    },
    parkedStyle () {
      return (value) => {
        return value.VEHICLE_STATUS_ID === 0 ? 'parked': ''
      }
    },
    nearDeadlineStyle () {
      return (value) => {
        return value.END_DTE !== null && moment(value.END_DTE).isSameOrBefore(moment().add(1, 'months')) ? 'near-deadline': ''
      }
    },
    selectHeaders: {
      get () { return (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md') ? this.mobileHeaders : this.headers}
    },
    headers () {
      return [
        { text: '所属', value: 'ORGANIZATION', sortable: false, filterable: true },
        { text: '車両番号', value: 'NUMBER', sortable: false, filterable: true },
        { text: '有効期間', value: '', sortable: false, filterable: false },
        { text: '入庫状況', value: 'VEHICLE_STATUS_ID', sortable: false, filterable: false },
        { text: '入庫時間', value: 'ENTRANT_DTE', sortable: false, filterable: false },
        { text: '対象駐車場', value: '', sortable: false, filterable: false }
      ]
    },
    mobileHeaders () {
      return [
        { text: '所属', value: 'ORGANIZATION', sortable: false, filterable: true },
        { text: '車両番号', value: 'NUMBER', sortable: false, filterable: true },
        { text: '有効期間', value: '', sortable: false, filterable: false }
      ]
    },
    sortDescSelect: {
      get () { return this.sortBy === 'VEHICLE_STATUS_ID' ? this.sortDescInOutStatus : this.sortDescItems }
    },
    pageItems: {
      get () { return [ { text: '5', value: 5 },{ text: '10', value: 10 },{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'ALL', value: this.noPaymentRequiredVehiclesCount }] }
    },
    minEndDate () {
      return this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).add(1, 'days').format('yyyy-MM-DD') : null
    },
    maxStartDate () {
      return this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).subtract(1, 'days').format('yyyy-MM-DD') : null
    },
    currentItems () {
      let selectItems = []
      if (this.isSearchOrganization && this.isSearchCarNumber) {
        selectItems = this.noPaymentRequiredVehicles.filter((vehicle) => vehicle.NUMBER && vehicle.ORGANIZATION && vehicle.NUMBER.indexOf(this.searchCarNumber) !== -1 && vehicle.ORGANIZATION.indexOf(this.searchOrganization) !== -1)
      } else if (this.isSearchOrganization && !this.isSearchCarNumber) {
        selectItems = this.noPaymentRequiredVehicles.filter((vehicle) => vehicle.ORGANIZATION && vehicle.ORGANIZATION.indexOf(this.searchOrganization) !== -1)
      } else if (this.isSearchCarNumber && !this.isSearchOrganization){
        selectItems = this.noPaymentRequiredVehicles.filter((vehicle) => vehicle.NUMBER && vehicle.NUMBER.indexOf(this.searchCarNumber) !== -1)
      } else {
        selectItems = this.noPaymentRequiredVehicles
      }
      if (this.selectedParking && this.selectedParking.PARKING_ID === 0) {
        selectItems = selectItems.filter((vehicle) => Number(vehicle.PARKING_ID) === 0)
      } else if (this.selectedParking && this.selectedParking.PARKING_ID !== null) {
        selectItems = selectItems.filter((vehicle) => Number(vehicle.PARKING_ID) === Number(this.selectedParking.PARKING_ID))
      }
      if (this.filter === 0) {
        return selectItems
      } else if (this.filter === 1) {
        return selectItems.filter((vehicle) => vehicle.END_DTE !== null && moment(vehicle.END_DTE).isBetween(moment(), moment().add(1, 'months'), null, '[]'))
      } else {
        return selectItems.filter((vehicle) => vehicle.END_DTE !== null && moment(vehicle.END_DTE).isAfter(moment()) && moment(vehicle.END_DTE).isBetween(moment(), moment().add(2, 'months'), null, '[]'))
      }
    },
    // ゆがふのみ表示のため、store.idで区別している。 2023-08-22 tamaki 
    showOnlyStore () {
      if (this.selectedRequestClientStore) {
        return this.selectedRequestClientStore.id == 361
      } else {
        return false
      }
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getParkings()
        this.selectedParking = {PARKING_ID: null, PARKING_NAME: ""}
        this.getFilteredNoPaymentRequiredVehicles()
      }
    },
    selectedTargetStartDate (value) {
      if (value) { this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDateFormatted (value) {
      if (!value) { this.selectedTargetStartDate = '' }
    },
    selectedTargetEndDateFormatted (value) {
      if (!value) { this.selectedTargetEndDate = '' }
    },
    searchOrganization (value) {
      if (value === '' || value === null) {
        this.isSearchOrganization = false
      } else {
        this.isSearchOrganization = true
      }
    },
    searchCarNumber (value) {
      if (value === '' || value === null) {
        this.isSearchCarNumber = false
      } else {
        this.isSearchCarNumber = true
      }
    }
  },
  methods: {
    getFilteredNoPaymentRequiredVehicles () {
      this.page = 1
      this.getNoPaymentRequiredVehicles()
    },
    async getNoPaymentRequiredVehicles () {
      this.filter = 0
      this.sortBy = ''
      this.itemsPerPage = 10
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}no_payment_required_vehicles?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      await this.axios
        .get(url)
        .then(response => {
          console.log(response.data)
          this.noPaymentRequiredVehicles = response.data.vehicles
          this.noPaymentRequiredVehiclesCount = response.data.no_payment_required_vehicles_count
          this.parkingInfo = response.data.parking_info
          this.showParkingInfo = true
          if (this.selectedRequestClientStore.white_limit > 0 && this.noPaymentRequiredVehiclesCount > 0 && this.selectedRequestClientStore.white_limit <= this.noPaymentRequiredVehiclesCount) {
            this.isRegisterableNewWhite = false
          }
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    async getParkings () {
      const url = `${process.env.VUE_APP_BASE_URL}/parkings/parking_names?&ip_address=${this.selectedRequestClientStore.address}`
      this.axios
        .get(url)
        .then(response => {
          // const responseData = response.data
          // 特定の店舗のみの処置 2024-12-14 やめたい
          if (response.data.length > 1) {
            this.parkings = [{PARKING_ID: null, PARKING_NAME: ""}, {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}].concat(response.data)
          } else {
            this.parkings = [{PARKING_ID: null, PARKING_NAME: ""}, {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}]
          }
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
            this.parkings = [{PARKING_ID: null, PARKING_NAME: ""}, {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}]
          }
        })
    },
    async getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    showDialog (vehicle) {
      this.showedVehicle = vehicle
      this.dialog = true
    },
    async deleteVehicle () {
      this.deleteLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}no_payment_required_vehicles/${this.showedVehicle.WHITE_ID}`
      let data = {
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
      }
      const options = {
        url: url,
        method: 'DELETE',
        data: data,
      }
      await this.axios(options)
      .then(() => {
        this.$buefy.toast.open({
          message: `登録を削除しました`,
          type: "is-info"
        })
        this.deleteLoading = false
        this.getNoPaymentRequiredVehicles()
        this.closeConfirm()
        this.close()
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 404) {
          this.$buefy.toast.open({
            message: `対象の車両登録が見つかりません`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `登録削除に失敗しました`,
            type: "is-danger",
          })
        }
        this.closeConfirm()
        this.deleteLoading = false
      })
    },
    close () {
      this.dialog = false
    },
    closeConfirm () {
      this.confirmDialog = false
    },
    edit () {
      this.$router.push({name: "editNoPaymentRequiredVehicle", params: { id: this.showedVehicle.WHITE_ID, selectedRequestClientStore: this.selectedRequestClientStore, noPaymentRequiredVehicle: this.showedVehicle }, props: true })
    }
  },
  mounted() {
    this.getRequestClientStores()
  }
}
</script>

<style scoped>
.parked { background: #1F3BB333; color: #1F3BB3; }
.near-deadline { background: #F95F5333; color: #F95F53; }
.table-row { cursor: pointer; }
.custom-table { border-collapse: collapse; width: 100%; }
.custom-table th,.custom-table td { border: 1px solid #ccc; padding: 8px; text-align: center; vertical-align: middle; }
.header-cell { background-color: #2196f32b; }
.info-cell { background-color: #2196f32b; }
.custom-table tbody tr:hover { background-color: transparent !important; }
</style>
